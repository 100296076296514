<template>
  <div>
    <div v-if="viewMode == 'overview'" class="">
      <div id="myKart" v-on:click="viewMode = 'nameEditMode'">    
        <h3>My Kart</h3>
        <p v-if="!myKart">No Kart found for your name "{{myRacerName}}"</p>
        <div v-if="myKart">
          {{ myKart.driver }}, Kart {{ myKart.kart }}
        <!--
          bestLap: "50.147"
          bestLapNumber: 8
          bestST: "25.354"
          bestST2: "24.793"
          driver: "Bene"
          finished: false
          gap: "+0.544"
          grid: 9
          id: 1455843
          kart: "72"
          lap: 8
          lastLap: "50.147"
          lastST: "25.354"
          lastST2: "24.793"
          pit: "Pit 3"
          posDiff: 0
          position: 2
          team: ""
          totalTime: "07:10.873"
        -->
        </div>
      </div>
      <div id="myRace" v-on:click="viewMode = 'raceMode'"> 
        <h3>My Race</h3>
        <p v-if="!myRace">"No race found for your name"</p>
        <p v-if="myRace">
          <b>{{ myRace.track }}</b>: {{ myRace.name }}, {{ myRace.title }}, {{ myRace.startTime}}<span v-if="myRace.finished">, <b>beendet</b></span>
          <br><i>(last update: {{myRaceLastUpdate}} seconds ago at {{new Date(myRace.lastUpdate)}})</i>
          
        </p>
      </div>
      <div id="allRaces"> 
        <h3 v-on:click="viewMode = 'debugMode'">All Races</h3>
        <div id="raceList">
          <p v-for="race in runningRaces" :key="race.track">
            <b>{{ race.track }}</b
            >: {{ race.name }}, {{ race.startTime
            }}<span v-if="race.finished">, <b>beendet</b></span>
          </p>
        </div>
        </div>
    </div>
    <div v-if="viewMode == 'nameEditMode'">
      <input v-model="myRacerName" type="text" />
      <button v-on:click="viewMode = 'overview'">Save my name</button>
    </div>
    <div id="raceMode" v-on:click="toggleFullscreen" v-if="viewMode == 'raceMode'">
      <div v-if="myKart">        
        <span v-bind:class="flashUpdate ? 'flash' : 'noflash'">last: <span class="big">{{ myKart.lastLap }}</span>
        best: <span class="medium">{{ myKart.bestLap }}</span></span><br>        
        <span>sector1: <span class="medium">{{ myKart. lastST }}</span> sector2: <span class="medium">{{ myKart. lastST2 }}</span></span><br> 
        <hr>
        <span><span v-if="kartInFront"><span class="medium">{{ myKart.gap.substring(1,4) }}</span> hinter <span class="small">{{ kartInFront.driver.substring(0,7)}} | </span></span>
        <span v-if="kartBehind"><span class="medium">{{ kartBehind.gap.substring(1,4) }}</span> vor <span class="small">{{ kartBehind.driver.substring(0,7) }}</span></span></span><br>        
        position: <span class="small">{{ myKart.position }}</span> laps remaining:  <span class="small">{{ myRace.remainingLaps }} </span><br>
        <br><br><br><i>(last update: {{myRaceLastUpdate}} seconds ago at {{new Date(myRace.lastUpdate)}})</i>
      </div>
      <br><a href="#" v-on:click="viewMode = 'overview'">back</a>      
    </div>
     <div v-if="viewMode == 'debugMode'">
       <pre>{{JSON.stringify(runningRaces, null, 2)}}</pre>
       <br><br><a href="#" v-on:click="viewMode = 'overview'">back</a>
     </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueCookies from "vue-cookies";
import NoSleep from 'nosleep.js';
var noSleep = new NoSleep();
Vue.use(VueCookies);

export default {
  name: "HelloWorld",
  props: {
    runningRaces: {},
  },
  methods: {
    toggleFullscreen() {
         document.documentElement.requestFullscreen()
         noSleep.enable();
    }
  },
  data() {
    return {
      myRacerName: "Click to enter your name",
      myRace: null,
      myRaceLastUpdate: 0,
      myKart: undefined,
      kartInFront: null,
      kartBehind: null,
      flashUpdate: false,
      viewMode: "raceMode",
    };
  },
  watch: {
    myRacerName: {
      immediate: true,
      handler(val) {
        if (val == "Click to enter your name") {
          let cookieVal = Vue.$cookies.get("myRacerName");
          if (cookieVal) this.myRacerName = cookieVal;
        } else {
          Vue.$cookies.set("myRacerName", val, "1y");
        }
      },
    },
    runningRaces: {
      immediate: true,
      deep: true,
      handler(val) {
        //console.log("updated runningRaces", val);
        this.myRace = Object.entries(val).find((race) => {
          this.myKart = race[1].karts.find((kart) => {
            return kart.driver.toLowerCase() == this.myRacerName.toLowerCase();
          });
          if (this.myKart) {          
            this.kartInFront = race[1].karts.find((kart) => {
              return kart.position == this.myKart.position -1;
            });
            
            this.kartBehind = race[1].karts.find((kart) => {
              return kart.position == this.myKart.position + 1;
            });
          }          
          return this.myKart;
        })?.[1];
        if (this.myRace) {
          this.flashUpdate = true
          setTimeout(() => this.flashUpdate = false, 5000)
          setInterval(() => {
            this.myRaceLastUpdate = Math.round((Date.now() - this.myRace.lastUpdate) / 1000)
          }, 1000);
        //Math.round((Date.now() - myRace.lastUpdate) / 1000 / 60)}} minutes ago at {{new Date(myRace.lastUpdate)}})</i>
        //this.myRaceLastUpdate = this.myRace.lastUpdate;
        }
      },
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

#raceMode .big {
  font-size: 16vw;
}

#raceMode .medium {
  font-size: 9vw;
}

#raceMode .small {
  font-size: 7vw;
}

.flash {
  color: #0f0;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.noflash {
  color: #0f0
}

</style>
