<template>
  <!--<img alt="Vue logo" src="./assets/logo.png">-->
  <div id="app">
    <HelloWorld :runningRaces="runningRaces"/>           
  </div>
</template>


<script>
import HelloWorld from './components/HelloWorld.vue'
import Vue from 'vue'

export default {
  name: 'App',
  async created() {    
    let wsDataResponse = await fetch('https://kpf-headup.drakane.workers.dev')    
    this.wsData = await wsDataResponse.json();
    console.log("wsData:", this.wsData);
    this.connection = new WebSocket(this.wsData.wsUrl);    
    let parent = this;
    this.connection.onmessage = function(event) {
      
      //console.log(event);
      event.data.split('\u001e').forEach(message => {
        if (message == "{}") {
          // subscribe to topics for all raceways
          parent.connection.send('{"arguments":["KartPalast_current_rt4"],"invocationId":"' + parent.invocationId++ + '","streamIds":[],"target":"joinGroup","type":1}\u001e');          
          parent.connection.send('{"arguments":["KartPalast_current_rt5"],"invocationId":"' + parent.invocationId++ + '","streamIds":[],"target":"joinGroup","type":1}\u001e');          
          parent.connection.send('{"arguments":["KartPalast_current_rt6"],"invocationId":"' + parent.invocationId++ + '","streamIds":[],"target":"joinGroup","type":1}\u001e');          
          parent.connection.send('{"arguments":["KartPalast_current_rt22"],"invocationId":"' + parent.invocationId++ + '","streamIds":[],"target":"joinGroup","type":1}\u001e');          
          parent.connection.send('{"arguments":["KartPalast_current_rt38"],"invocationId":"' + parent.invocationId++ + '","streamIds":[],"target":"joinGroup","type":1}\u001e');        
          parent.connection.send('{"arguments":["KartPalast_current_rt39"],"invocationId":"' + parent.invocationId++ + '","streamIds":[],"target":"joinGroup","type":1}\u001e');          
        } else if (message) {
          let updateMessage = JSON.parse(message)
          console.log(updateMessage);

          if (updateMessage.arguments?.[0]?.data?.track) {
            //console.log(updateMessage.arguments[0].data.name)            
            Vue.set(parent.runningRaces, updateMessage.arguments[0].data.track, updateMessage.arguments[0].data)                     
            parent.runningRaces[updateMessage.arguments[0].data.track].lastUpdate = updateMessage.arguments[0].timeStamp
          } else {
            //console.log("some other message")
          }
        }
      })      
    }

    this.connection.onopen = function(event) {
      console.log(event)
      console.log("Successfully connected to the echo websocket server...")
        parent.connection.send('{"protocol":"json","version":1}\u001e');
       
      setInterval(() => {
              parent.connection.send('{"type":6}\u001e');
      }, 15000)
      
    }
  },
  data() {
    return {
      runningRaces: {},
      invocationId: 0
    }
  },
  components: {
    HelloWorld
  }
}
</script>

<style>
body {
background-color: black;
  color: white;
   margin-top: 0px;
}
#app {
  
}

</style>
